body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@font-face {
	font-family: 'Iqos';
	src: local('Iqos'), url(./assets/fonts/IQOSW04-Regular.woff2);
}
@font-face {
	font-family: 'IqosBold';
	src: local('IqosBold'), url(./assets/fonts/IQOSW04-Bold.woff2);
}

@font-face {
	font-family: 'IqosLight';
	src: local('IqosLight'), url(./assets/fonts/IQOSW04-Light.woff2);
}

html,
body,
#root,
.shade-container-wrapper,
.content,
.App-desktop,
.App-desktop > div,
.App-desktop > div > div,
.App > div,
.App > div > div,
.App {
	height: 100% !important;
}
