.sendbird-message-input path,
.sendbird-place-holder path {
	fill: #d6a76f;
}
.sendbird-conversation__messages .sendbird-separator {
	display: none;
}
.sendbird-avatar .sendbird-avatar-img {
	display: none;
}
.sendbird-user-message--incoming {
	padding-left: 0px;
}
.sendbird-user-message--incoming .sendbird-user-message__sender-name {
	margin-left: 2px;
}

.sendbird-user-message--incoming .sendbird-user-message__right-padding {
	display: none;
}
.sendbird-user-message--outgoing .sendbird-user-message__left-padding {
	display: none;
}
.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea {
	border: solid 1px transparent;
	caret-color: #00d1d2;
	color: #ecedf1;
	border-radius: 24px;
}
.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--placeholder {
	color: black;
	font-family: 'Iqos';
}
.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea:focus {
	color: black;
	border: solid 1px transparent;
	font-family: 'Iqos';
	border-radius: 24px;
}
.sendbird-theme--dark .sendbird-iconbutton:active {
	background-color: transparent;
}
